import React from 'react'
import { Line } from 'react-chartjs-2'

import t from 'prop-types'
import { ResponsiveContainer } from 'recharts'

import { useAuth } from '~/contexts/auth'

import { Container } from './styles'

function formatDataForChart({ data, backgroundColorByAppearence }) {
  const uniqueDates = data
    .map((item) => item.day)
    .filter((a, index, self) => self.indexOf(a) === index)

  const groupedData = {}

  data.forEach(({ day, answer, count }) => {
    if (!groupedData[answer]) {
      groupedData[answer] = {}
    }
    groupedData[answer][day] = count
  })

  const colors = {
    Coletado: { borderColor: 'blue', backgroundColor: 'rgba(0, 0, 255, 0.1)' },
    'Não Coletado': {
      borderColor: 'rgba(255, 0, 0, 1)',
      backgroundColor: 'rgba(255, 0, 0, 0.1)',
    },
    Entregue: {
      borderColor: 'rgba(0, 255, 0, 1)',
      backgroundColor: 'rgba(0, 255, 0, 0.1)',
    },
    Agendado: {
      borderColor: 'rgba(128, 128, 128, 1)',
      backgroundColor: 'rgba(128, 128, 128, 0.1)',
    },
  }

  const datasets = Object.keys(groupedData).map((answer) => ({
    label: answer,
    data: uniqueDates.map((date) => groupedData[answer][date] || 0),
    borderColor:
      colors[answer]?.borderColor ||
      backgroundColorByAppearence ||
      'rgba(0, 43, 93, 1)',
    backgroundColor:
      colors[answer]?.backgroundColor ||
      `${backgroundColorByAppearence}1A` ||
      'rgba(0, 43, 93, 0.1)',
    fill: true,
  }))

  return {
    labels: uniqueDates,
    datasets,
  }
}

export function LinearCount({ data }) {
  const { data: dataCustomer } = useAuth()
  const backgroundColor = dataCustomer?.appearence?.backgroundColor

  const options = {
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      title: {
        display: true,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
  }

  const dataValues = formatDataForChart({
    data,
    backgroundColorByAppearence: backgroundColor,
  })

  return (
    <Container>
      <ResponsiveContainer width="100%" height={300}>
        <Line options={options} data={dataValues} />
      </ResponsiveContainer>
    </Container>
  )
}

LinearCount.propTypes = {
  data: t.oneOfType([t.arrayOf(t.string), t.object]),
}
