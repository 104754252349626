import React, { memo, useCallback, useEffect, useState } from 'react'

import { Row, Col, Empty } from 'antd'
import t from 'prop-types'

import Loading from '~/components/atoms/Loading'
import api from '~/services/api'

import { Card } from './Card'
import { ChartTypes } from './Charts/ChartTypes'

export function CardItem({ chart, filters, onChange, onDelete }) {
  const [loading, setLoading] = useState(true)
  const [chartConfig, setChartConfig] = useState(() => {
    if (!chart?.data?.id) {
      return { ...chart?.data, id: chart?.uid }
    }
    return chart?.data
  })
  const [chartValue, setChartValue] = useState(null)

  const isBarFullSize =
    chartConfig.size === 'full' && chartConfig.type === 'bar_count'

  const getChart = useCallback(async () => {
    setLoading(true)

    const response = await api.post(
      `/analytics-data/chart/${chart.uid}`,
      filters,
      {
        validateStatus() {
          return true
        },
      }
    )

    setChartValue(response.data.chart)
    setLoading(false)
  }, []) //eslint-disable-line

  useEffect(() => {
    ;(async () => {
      getChart()
    })()
  }, []) //eslint-disable-line

  const isValidChart =
    chartValue !== null &&
    typeof chartValue === 'object' &&
    Object.keys(chartValue).length > 0

  return (
    <Card.Root
      title={chartConfig?.title}
      size={chartConfig?.size}
      extra={
        <Card.ExtraButtons
          config={chartConfig}
          filters={filters}
          onDelete={() => onDelete(chart.uid)}
          onChange={(val) => {
            setChartConfig((prev) => ({ ...prev, ...val }))
            onChange({ ...val, uid: chart.uid })
          }}
        />
      }
    >
      <div style={{ cursor: 'default' }}>
        <Row>
          <Col
            span={
              chartConfig?.size === 'full' && chartConfig?.type === 'bar_count'
                ? 18
                : 24
            }
          >
            {loading ? <Loading /> : null}

            {!loading && isValidChart ? (
              <ChartTypes data={chartValue} type={chartConfig?.type} />
            ) : (
              <Empty />
            )}
          </Col>
          {isBarFullSize && isValidChart ? (
            <Card.Table chartsItems={chartValue} />
          ) : null}
        </Row>
      </div>
    </Card.Root>
  )
}

CardItem.propTypes = {
  chart: t.objectOf(),
  onDelete: t.func,
  onChange: t.func,
  filters: t.objectOf({
    start: t.string,
    final: t.string,
    missions: t.arrayOf([t.string]),
  }),
}

export default memo(CardItem)
