import React from 'react'

import {
  DownloadOutlined,
  DeleteOutlined,
  FileZipOutlined,
} from '@ant-design/icons'
import { Button } from 'antd'
import { format } from 'date-fns'

const actionButton = ({
  item,
  handlerUserReceipt,
  removeReport,
  openZipLinkModal,
}) => {
  const isZip = item.url && item.is_zip
  const isExcel = item.url && !item.is_zip
  return (
    <>
      {isExcel && (
        <Button
          shape="round"
          title="Baixar"
          style={{ marginRight: 10 }}
          icon={<DownloadOutlined />}
          onClick={() => handlerUserReceipt(item.url)}
        />
      )}
      {isZip && (
        <Button
          shape="round"
          title="Baixar images ou fotos"
          icon={<FileZipOutlined />}
          onClick={() => openZipLinkModal(item)}
        />
      )}
      &nbsp;
      <Button
        danger
        shape="round"
        title="Remover Item"
        icon={<DeleteOutlined />}
        onClick={() => removeReport(item)}
      />
    </>
  )
}

export function columns({
  handlerUserReceipt,
  removeReport,
  openZipLinkModal,
}) {
  return [
    {
      title: 'ID',
      dataIndex: 'uid',
    },
    {
      title: 'Formulário',
      dataIndex: 'form_name',
    },
    {
      title: 'Subcategoria',
      dataIndex: 'missions_main_name',
    },
    {
      title: 'Periodo',
      dataIndex: '',
      render: (i) => (
        <span>
          {`${format(new Date(i.initialized_at), 'dd/MM/yyyy HH:mm')} -
            ${format(new Date(i.finalized_at), 'dd/MM/yyyy HH:mm')}`}
        </span>
      ),
    },
    {
      title: 'Criado Em',
      dataIndex: 'created_at',
      render: (createdAt) => format(new Date(createdAt), 'dd/MM/yyyy HH:mm'),
    },
    {
      title: 'Atualizado Em',
      dataIndex: 'updated_at',
      render: (updatedAt) =>
        updatedAt ? format(new Date(updatedAt), 'dd/MM/yyyy HH:mm') : null,
    },
    {
      title: 'Situação',
      dataIndex: 'status',
    },
    {
      key: 'x',
      title: 'Ações',
      dataIndex: '',
      render: (item) =>
        actionButton({
          item,
          handlerUserReceipt,
          removeReport,
          openZipLinkModal,
        }),
    },
  ]
}

export const TYPE_EXPORTATION = {
  XLS: 'xls',
  ZIP_IMAGES: 'zip_images',
  ZIP_PHOTOS: 'zip_photos',
}
