/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-bind */
import React, { memo, useState } from 'react'

import {
  AreaChartOutlined,
  DeleteOutlined,
  EditOutlined,
  ExpandAltOutlined,
  FileExcelOutlined,
  ShrinkOutlined,
} from '@ant-design/icons'
import {
  Popconfirm,
  Button,
  Tooltip,
  Select,
  Modal,
  Form,
  Input,
  message,
} from 'antd'
import dayjs from 'dayjs'
import * as FileSaver from 'file-saver'
import t from 'prop-types'

import api from '~/services/api'

export function ExtraButtons({ config, onChange, filters, onDelete }) {
  const entitysFromDeliveryChart = [
    'missions_feeds',
    'missions_users_delivery',
    'missions_users_collect',
  ]

  const optionsCharts = [
    { label: 'Barra (%)', value: 'bar_avg' },
    { label: 'Barra (Total)', value: 'bar_count' },
    { label: 'Doughnut (%)', value: 'doughnut' },
    ...(!entitysFromDeliveryChart.includes(config?.entity)
      ? [{ label: 'Linear (Total)', value: 'linear' }]
      : []),
  ]

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const start = dayjs().subtract(3, 'month').format('YYYY-MM-YY')
  const final = dayjs().format('YYYY-MM-YY')

  async function onExport() {
    setLoading(true)
    const data = {
      start,
      final,
      ...filters,
      data: 'xls',
    }

    try {
      const res = await api.post(`/analytics-data/chart/${config?.id}`, data, {
        responseType: 'arraybuffer',
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error('Houve um erro para gerar o arquivo')
      }

      const blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      })

      return FileSaver.saveAs(
        blob,
        `${config?.title.replace(
          /[^A-Z0-9]/gi,
          '_'
        )}_dados_analiticos_${new Date().getTime()}`
      )
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  async function onConfirmEdit(value) {
    setOpenEdit(false)
    onChange({ ...config, ...value })
  }

  return (
    <>
      <Tooltip title="Editar">
        <Button
          type="text"
          size="large"
          loading={loading}
          icon={<EditOutlined />}
          onClick={() => setOpenEdit(true)}
        />
        <Modal
          title="Editar Chart"
          open={openEdit}
          footer={null}
          onCancel={() => setOpenEdit(false)}
        >
          <Form onFinish={onConfirmEdit}>
            <Form.Item label="Título" name="title" initialValue={config?.title}>
              <Input placeholder="Título do Card" />
            </Form.Item>
            {!['missions_approved'].includes(config?.entity) ? (
              <Form.Item
                label="Tipo de Gráfico"
                name="type"
                initialValue={config?.type}
              >
                <Select
                  title="Tipo"
                  size={200}
                  options={optionsCharts}
                  suffixIcon={<AreaChartOutlined />}
                />
              </Form.Item>
            ) : null}
            <Form.Item wrapperCol={{ offset: 19, span: 24 }}>
              <Button type="primary" htmlType="submit">
                Atualizar
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Tooltip>
      <Tooltip
        title={`Redimensionar ${config?.size === 'full' ? '(50%)' : '(100%)'}`}
      >
        <Button
          type="text"
          size="large"
          loading={loading}
          onClick={() =>
            onChange({
              ...config,
              size: config?.size === 'full' ? 'half' : 'full',
            })
          }
          icon={
            config?.size === 'full' ? <ShrinkOutlined /> : <ExpandAltOutlined />
          }
        />
      </Tooltip>
      <Tooltip title="Exportar Dados">
        <Button
          type="text"
          size="large"
          loading={loading}
          onClick={onExport}
          icon={<FileExcelOutlined />}
          style={styles.buttonStyles}
        />
      </Tooltip>
      <Tooltip title="Remover Gráfico">
        <Popconfirm
          okText="Sim"
          open={open}
          cancelText="Cancelar"
          onConfirm={onDelete}
          onCancel={() => setOpen(false)}
          title="Tem certeza que você deseja remover esse gráfico?"
        >
          <Button
            type="text"
            size="large"
            icon={<DeleteOutlined />}
            onClick={() => setOpen(true)}
            style={styles.buttonStyles}
          />
        </Popconfirm>
      </Tooltip>
    </>
  )
}

const styles = { buttonStyles: { color: '#4B5C6B', zIndex: 2 } }

ExtraButtons.propTypes = {
  config: t.objectOf({
    title: t.string,
    description: t.string,
    entity: t.string,
    type: t.string,
    size: t.string,
  }),
  onDelete: t.func,
  onChange: t.func,
}

export default memo(ExtraButtons)
