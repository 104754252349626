/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { Row } from 'antd'
import arrayMove from 'array-move'
import t from 'prop-types'

import useQueryFilters from '~/components/molecules/Filter/useQueryFilters'
import useAnalytics from '~/hooks/useAnalytics'

import { CardItem } from '../CardItem'

export function Charts({ chartsData, params }) {
  const { getUrlQuery } = useQueryFilters()
  const { onDeleteChart, onReorderChart, onChangeChart } = useAnalytics()

  const [items, setItems] = React.useState([])

  function onSortEnd({ oldIndex, newIndex }) {
    const newItems = arrayMove(items, oldIndex, newIndex)

    setItems(newItems)
    onReorderChart({ items: newItems })
  }

  function onDeleteCard(id) {
    const newItems = items.filter((i) => i?.uid !== id)

    setItems(newItems)

    return onDeleteChart(id)
  }

  function onChange(value) {
    const index = items.findIndex((i) => i.uid === value.uid)

    items[index] = {
      ...items[index],
      data: { ...items[index]?.data, ...value },
    }

    setItems((_) => [...items])
    onChangeChart({ data: { ...items[index]?.data, id: value.uid } })
  }

  const SortableItem = SortableElement(({ value }) => {
    return (
      <CardItem
        key={value?.id}
        onChange={onChange}
        chart={value}
        onDelete={onDeleteCard}
        filters={{ ...getUrlQuery(), ...params }}
      />
    )
  })

  const SortableList = SortableContainer(({ items: chartsItems }) => {
    return (
      <Row>
        {chartsItems?.map((value, index) => (
          <SortableItem index={index} value={value} />
        ))}
      </Row>
    )
  })

  function shouldCancelStart(e) {
    if (e.target.tagName.toLowerCase() === 'canvas') {
      return true
    }
    return false
  }

  useEffect(() => {
    ;(async () => {
      setItems(chartsData)
    })()
  }, [items, chartsData]) //eslint-disable-line

  return (
    <SortableList
      axis="xy"
      items={items}
      onSortEnd={onSortEnd}
      distance={1}
      onSortStart={() => {
        document.body.style.cursor = 'grabbing'
      }}
      shouldCancelStart={shouldCancelStart}
    />
  )
}

Charts.propTypes = {
  chartsData: t.arrayOf(),
  params: t.arrayOf(),
}
