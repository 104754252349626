/* eslint-disable func-names */
import { useCallback, useState } from 'react'

import { message } from 'antd'
import * as Yup from 'yup'

import api from '~/services/api'

export default function useReports() {
  const [loadingGenerate, setLoadingGenerate] = useState(false)
  const [loadingGenerateZip, setLoadingGenerateZip] = useState(false)
  const [reports, setReports] = useState([])
  const [loadingReports, setLoadingReports] = useState(false)
  const [pagination, setPagination] = useState({
    total: 0,
    current: 0,
    pageSize: 0,
  })

  const [reportSelected, setReportSelected] = useState(null)

  function clearObject(data) {
    Object.keys(data).forEach((i) => data[i] === null && delete data[i])

    return data
  }

  const removeReport = async (item) => {
    try {
      setLoadingReports(true)
      const res = await api.delete(`customers/${item.uid}`)
      if (res.status !== 204) {
        throw new Error(res.data.message)
      }

      return message.success('Registro foi removido com sucesso')
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingReports(false)
    }
  }

  const getAll = useCallback(async ({ offset, limit }) => {
    try {
      setLoadingReports(true)
      const res = await api.get('customers/reports', {
        params: { offset, limit, all: true },
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      let count = 0

      if (res?.data?.length > 0) {
        count = res.data[0].total_rows
      }

      setPagination((prevState) => ({
        ...prevState,
        total: Number(count),
        pageSize: res.data.length,
        current: offset / limit + 1,
      }))

      return setReports(res.data)
    } catch (error) {
      return message.error(error.message)
    } finally {
      setLoadingReports(false)
    }
  }, [])

  const onGeneratePhotosZipped = async ({ customerId, initial, final }) => {
    try {
      const data = { customerId, initial, final }

      const schema = Yup.object().shape({
        initial: Yup.string('Data inicial deve ser informada.').required(
          'Data inicial deve ser informada.'
        ),
        final: Yup.string('Data final deve ser informada.').required(
          'Data final deve ser informada.'
        ),
        customerId: Yup.string().uuid().required(),
      })

      const isValid = schema.isValidSync(data)

      if (!isValid) {
        const validate = schema.validateSync(data)
        throw new Error(validate)
      }

      setLoadingGenerateZip(true)

      const res = await api.post(`reports/photos`, data)

      return message.success(
        `Suas fotos estão sendo processadas é serão enviadas para o email: ${res.data.emailUsedToSendZipLinks}`
      )
    } catch (error) {
      if (error.response) {
        return message.error(error.response.data.message)
      }
      return message.error(error.message)
    } finally {
      setLoadingGenerateZip(false)
    }
  }

  const onGenerateImagesZipped = async ({
    customerId,
    initial,
    final,
    formsId,
  }) => {
    try {
      const data = { customerId, initial, final, formId: formsId }

      const schema = Yup.object().shape({
        initial: Yup.string('Data inicial deve ser informada.').required(
          'Data inicial deve ser informada.'
        ),
        final: Yup.string('Data final deve ser informada.').required(
          'Data final deve ser informada.'
        ),
        customerId: Yup.string().uuid().required(),
        formId: Yup.string('É necessário selecionar um formulário')
          .uuid('É necessário selecionar um formulário')
          .required('É necessário selecionar um formulário'),
      })

      const isValid = schema.isValidSync(data)

      if (!isValid) {
        const validate = schema.validateSync(data)
        throw new Error(validate)
      }

      setLoadingGenerateZip(true)

      const res = await api.post(`reports/images`, data)

      return message.success(
        `Suas imagens estão sendo processadas é serão enviadas para o email: ${res.data.emailUsedToSendZipLinks}`
      )
    } catch (error) {
      if (error.response) {
        return message.error(error.response.data.message)
      }
      return message.error(error.message)
    } finally {
      setLoadingGenerateZip(false)
    }
  }

  const onGenerateReports = useCallback(async (params) => {
    const cleanValues = clearObject(params)

    try {
      const schema = Yup.object()
        .shape({
          formsId: Yup.string().uuid(),
          missionsMainId: Yup.string().uuid(),
          registersId: Yup.array(Yup.string().uuid().required()).notRequired(),
          initial: Yup.date().notRequired().nullable(),
          final: Yup.date().notRequired().nullable(),
          type: Yup.string().equals(['xls']).notRequired(),
        })
        .test(
          'forms-or-missions-main-required',
          'Selecione pelo menos um formulário ou subcategoria.',
          function (value) {
            return !!value.formsId || !!value.missionsMainId
          }
        )

      const isValid = schema.isValidSync(cleanValues)

      if (!isValid) {
        const validate = schema.validateSync(cleanValues)
        throw new Error(validate)
      }

      setLoadingGenerate(true)
      const res = await api.post('forms-data/export', cleanValues, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 200) {
        throw new Error(res.data.message)
      }

      const { email } = res.data

      setLoadingGenerate(false)

      return message.warning(
        `O relatório está sendo gerado e deve demorar alguns minutos. Ao finalizar será encaminhado para o email ${email}`,
        10
      )
    } catch (error) {
      setLoadingGenerate(false)

      return message.error(error.message || 'Nenhuma resposta foi encontrada!')
    }
  }, [])

  const onGenerateExportationCollectData = useCallback(async (params) => {
    const cleanValues = clearObject(params)

    try {
      const schema = Yup.object().shape({
        missionsMainId: Yup.string().uuid(),
        initial: Yup.date().notRequired().nullable(),
        final: Yup.date().notRequired().nullable(),
        type: Yup.string().equals(['xls']).notRequired(),
      })

      const isValid = schema.isValidSync(cleanValues)

      if (!isValid) {
        const validate = schema.validateSync(cleanValues)
        throw new Error(validate)
      }

      setLoadingGenerate(true)
      const res = await api.post('delivery/export', cleanValues, {
        validateStatus() {
          return true
        },
      })

      if (res.status !== 201) {
        throw new Error(res.data.message)
      }

      const { email } = res.data

      setLoadingGenerate(false)

      return message.warning(
        `O relatório está sendo gerado e deve demorar alguns minutos. Ao finalizar será encaminhado para o email ${email}`,
        10
      )
    } catch (error) {
      setLoadingGenerate(false)

      return message.error(error.message || 'Nenhuma resposta foi encontrada!')
    }
  }, [])

  async function generateZipSignedUrls(data) {
    data.urls = data.url.split(',')

    const promiseSignedUrls = []
    for (let index = 0; index < data.urls.length; index += 1) {
      const link = data.urls[index]
      const linkSplited = link.split('/')
      const filename = linkSplited[linkSplited.length - 1]
      promiseSignedUrls.push(
        api.get(
          `reports/zips-signed-url?type=ZIP&uid=${data.uid}&filename=${filename}`
        )
      )
    }

    const signedUrls = await Promise.all(promiseSignedUrls)
    for (let index = 0; index < signedUrls.length; index += 1) {
      data.urls[index] = signedUrls[index].data.signedUrl
    }

    setReportSelected(data)
  }

  return {
    getAll,
    reports,
    pagination,
    loadingReports,
    loadingGenerate,
    loadingGenerateZip,
    onGenerateExportationCollectData,
    onGenerateReports,
    onGenerateImagesZipped,
    removeReport,
    generateZipSignedUrls,
    reportSelected,
    setReportSelected,
    onGeneratePhotosZipped,
  }
}
